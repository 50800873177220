import $ from "jquery";

const root = document.querySelector("#root");
// console.log(root);

const utilityBox = document.createElement("div");

root.appendChild(utilityBox);

$(utilityBox).css({
  position: "fixed",
  background: "red",
  width: "200px",
  height: "200px"
});

utilityBox.appendChild(document.createTextNode("HELLO"));
